import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 260.000000 280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,280.000000) scale(0.100000,-0.100000)">
     


     <path d="M0 1500 l0 -1300 1300 0 1300 0 0 1300 0 1300 -1300 0 -1300 0 0
-1300z m1458 784 c260 -54 486 -244 575 -484 32 -85 41 -124 27 -115 -6 4 -7
-2 -2 -17 5 -13 8 -52 8 -88 l-1 -65 -32 -3 -33 -3 0 48 c0 143 -110 362 -232
464 -214 178 -478 226 -723 130 -238 -92 -419 -326 -441 -570 l-7 -72 -46 3
-46 3 3 55 c5 90 31 179 83 287 128 265 403 439 702 442 48 1 122 -6 165 -15z
m42 -152 c36 -12 94 -38 129 -59 69 -40 181 -142 181 -164 0 -7 -24 10 -52 38
-262 254 -647 252 -922 -6 -39 -36 -54 -46 -42 -28 29 46 48 67 106 112 72 55
146 92 234 116 95 26 271 21 366 -9z m415 -391 c63 -153 58 -372 -11 -505 -41
-80 -45 -75 -13 17 43 123 56 228 42 326 -11 76 -43 188 -63 223 -15 26 -12
32 6 14 8 -9 26 -42 39 -75z m-1210 -5 c-55 -164 -55 -312 0 -470 13 -36 19
-66 15 -66 -14 0 -51 92 -67 164 -17 78 -13 239 8 311 14 49 59 148 65 142 3
-2 -7 -39 -21 -81z m525 25 c0 -8 -32 -17 -95 -25 -36 -4 -135 15 -135 26 0 4
52 8 115 8 63 0 115 -4 115 -9z m470 -8 c0 -17 -17 -18 -227 -17 -205 0 -228
2 -231 17 -3 16 17 17 227 17 213 0 231 -1 231 -17z m-595 -43 c19 -7 24 -7
19 1 -4 7 -1 10 7 7 21 -7 27 -49 28 -208 1 -140 -9 -212 -30 -226 -6 -3 -21
-7 -35 -9 l-25 -2 2 223 c1 123 3 224 5 224 1 0 14 -4 29 -10z m165 -2 c0 6 4
12 9 12 5 0 1 -20 -8 -45 -19 -55 -31 -58 -31 -7 0 43 9 59 22 40 6 -10 8 -10
8 0z m237 -2 c3 -2 7 -64 10 -138 6 -148 -3 -300 -18 -277 -5 8 -9 9 -9 3 0
-6 -13 -9 -30 -6 l-30 4 0 198 c0 109 2 205 6 213 5 13 59 15 71 3z m197 -19
c18 -67 -10 -76 -29 -10 -11 37 -11 40 6 36 9 -3 20 -15 23 -26z m-1093 -303
c51 -267 224 -468 476 -552 69 -23 94 -26 213 -26 120 0 144 3 215 27 251 84
436 301 477 561 13 82 6 76 81 79 20 1 22 -3 20 -53 -2 -78 -29 -173 -79 -277
-79 -160 -245 -315 -410 -380 -66 -26 -78 -27 -290 -31 -215 -4 -223 -3 -293
21 -100 35 -172 80 -259 162 -151 142 -232 296 -258 494 -8 61 -2 68 53 66
l37 -1 17 -90z m387 -76 c16 -16 16 -42 0 -31 -18 11 -42 10 -50 -2 -5 -6 -8
-4 -8 6 0 17 19 39 35 39 6 0 16 -5 23 -12z m528 -51 c4 -4 20 -7 36 -7 15 0
28 -4 28 -10 0 -6 -45 -10 -115 -10 -70 0 -115 4 -115 10 0 6 10 10 23 10 12
0 29 5 37 10 16 11 95 8 106 -3z m-445 -18 c-25 -21 -81 -25 -112 -9 -34 19
-20 25 58 27 77 1 77 1 54 -18z m744 -130 c-26 -50 -128 -144 -201 -185 -148
-83 -325 -106 -489 -64 -134 34 -273 124 -338 219 -30 45 -29 44 47 -26 161
-147 364 -216 546 -184 161 29 270 86 380 201 36 38 66 69 68 69 1 1 -5 -13
-13 -30z"/>
<path d="M503 100 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M1073 100 c-1 -25 1 -37 4 -27 5 18 5 18 26 0 22 -18 22 -18 4 3 -9
11 -14 24 -10 29 5 4 3 5 -4 1 -7 -4 -13 2 -16 16 -2 13 -4 3 -4 -22z"/>
<path d="M1568 133 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1638 109 c3 -20 0 -34 -9 -40 -10 -6 -5 -8 16 -8 17 0 24 3 18 6 -7
2 -13 20 -13 39 0 19 -4 34 -8 34 -5 0 -6 -14 -4 -31z"/>
<path d="M1721 116 c-1 -21 -5 -27 -16 -22 -8 3 -15 1 -15 -4 0 -6 6 -10 14
-10 8 0 16 -5 18 -12 8 -24 14 17 7 47 l-7 30 -1 -29z"/>
<path d="M1767 133 c-2 -4 -4 -21 -3 -38 1 -23 6 -30 21 -30 16 0 20 7 20 35
0 24 -5 36 -17 38 -9 2 -18 -1 -21 -5z m33 -13 c0 -5 -7 -10 -15 -10 -8 0 -15
5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m0 -35 c0 -8 -7 -15 -15 -15 -8 0
-15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z"/>
<path d="M1834 126 c-7 -18 3 -36 19 -35 9 0 9 2 0 6 -18 7 -16 33 2 33 21 0
19 -47 -2 -59 -10 -6 -13 -11 -7 -11 19 0 36 27 32 52 -4 26 -35 37 -44 14z"/>
<path d="M1930 135 c-1 -5 -1 -19 0 -29 1 -13 -3 -17 -14 -13 -9 4 -16 2 -16
-3 0 -6 6 -10 14 -10 8 0 16 -5 18 -12 8 -24 14 17 7 47 -4 17 -8 26 -9 20z"/>
<path d="M1970 120 c0 -13 6 -20 18 -19 10 0 12 3 5 6 -18 7 -16 21 5 26 13 4
11 5 -5 6 -17 1 -23 -5 -23 -19z"/>
<path d="M2053 133 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2125 100 c0 -34 3 -40 20 -39 11 0 14 3 8 6 -7 2 -13 20 -13 39 0
19 -3 34 -7 34 -5 0 -8 -18 -8 -40z"/>
<path d="M642 98 c2 -17 9 -33 16 -34 7 -1 8 0 2 3 -14 7 -13 40 2 45 7 3 5 7
-6 11 -15 6 -17 2 -14 -25z"/>
<path d="M682 98 c2 -17 9 -33 16 -34 7 -1 8 0 2 3 -14 7 -13 40 2 45 7 3 5 7
-6 11 -15 6 -17 2 -14 -25z"/>
<path d="M902 98 c2 -17 9 -33 16 -34 7 -1 8 0 2 3 -14 7 -13 40 2 45 7 3 5 7
-6 11 -15 6 -17 2 -14 -25z"/>
<path d="M445 109 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16 -11z"/>
<path d="M526 113 c7 -4 14 -19 17 -33 4 -22 5 -21 4 4 -1 17 -9 31 -17 33
-10 3 -11 2 -4 -4z"/>
<path d="M570 96 c0 -14 4 -27 8 -30 5 -3 6 8 4 25 -5 35 -12 38 -12 5z"/>
<path d="M612 90 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M749 112 c11 -8 11 -10 0 -15 -10 -4 -8 -6 4 -6 21 -1 18 21 -4 27
-12 3 -12 2 0 -6z"/>
<path d="M792 90 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M840 103 c0 -11 3 -14 8 -7 4 6 14 14 22 17 10 4 8 6 -7 6 -16 1 -23
-5 -23 -16z"/>
<path d="M944 106 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41 16z
m36 -16 c0 -11 -7 -20 -15 -20 -8 0 -15 9 -15 20 0 11 7 20 15 20 8 0 15 -9
15 -20z"/>
<path d="M1235 110 c-4 -6 -4 -14 -1 -17 3 -4 6 -1 6 5 0 7 7 12 15 12 9 0 15
-9 15 -21 0 -17 -5 -20 -21 -16 -16 4 -19 3 -11 -5 17 -17 44 -1 40 24 -3 25
-32 36 -43 18z"/>
<path d="M1302 90 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M1326 113 c7 -4 14 -19 17 -33 4 -22 5 -21 4 4 -1 17 -9 31 -17 33
-10 3 -11 2 -4 -4z"/>
<path d="M724 85 c1 -12 9 -21 21 -22 14 -1 15 0 3 3 -9 3 -19 13 -21 22 -3
12 -4 11 -3 -3z"/>
<path d="M1174 85 c1 -12 9 -21 21 -22 14 -1 15 0 3 3 -9 3 -19 13 -21 22 -3
12 -4 11 -3 -3z"/>
<path d="M1460 95 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M1382 80 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2010 87 c0 -8 -10 -18 -22 -20 l-23 -4 23 -2 c22 -1 41 23 28 36 -3
4 -6 -1 -6 -10z"/>
<path d="M470 82 c0 -6 -8 -13 -17 -15 -16 -4 -16 -5 -1 -6 18 -1 34 16 24 26
-3 4 -6 1 -6 -5z"/>
<path d="M870 82 c0 -6 -8 -13 -17 -15 -16 -4 -16 -5 -1 -6 18 -1 34 16 24 26
-3 4 -6 1 -6 -5z"/>
<path d="M2056 73 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M1018 68 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>


</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
